<template>
  <div class="footer"  style=" background-color: #2d3640; ">
    <footer-nav></footer-nav>
  </div>
</template>

<script>
  import FooterNav from "../../components/shared/bottomFooter";
  export default {
    name: "src-pages-footer-shared-v2",
    components: {FooterNav},
    data() {
      return {

      }
    },

    methods: {
    }
  }
</script>

<style>
  .footer{
    min-height: 340px;
    background: #ffffff;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding: 30px 124px 15px 124px;
  }

  .footer ul{
    list-style-type:none;
  }

  .footer li, .footer p{
    display:inline-block;
    font-size: 12px;
    color: #868a8a;
  }

  .footer li:after{
    content:'•';
    padding:0 7px;
  }

  .footer li:last-child:after{
    content:'';
  }
</style>
