<template>
  <div class="footer-body">
    <div class="footer-div">
      <div class="logo-div">
        <img src="https://gcdn.bionluk.com/site/general/bionluk_logo_gk_footer.png"/>
      </div>
      <div class="sections-body" v-for="(value, key) in footerLinks" v-if="key !== 'section2' && !isFooterLinksEmpty" :key="key">
        <p class="section-header">{{ value.name }}</p>
        <router-link v-for="(footerLink, index) in value.list" :to="footerLink.url" class="sections" :key="key + index">
          {{footerLink.name}}
        </router-link>
      </div>
      <div>
        <div>
          <a href="https://facebook.com/bionluk" target="_blank" style="margin-right: 20px">
            <img onload="SVGInject(this)"  src="https://gcdn.bionluk.com/site/c/ic-sm-facebook.svg" class="social-media-icon"/>
          </a>
          <a href="https://twitter.com/bionluk" target="_blank" style="margin-right: 20px">
            <img onload="SVGInject(this)"  src="https://gcdn.bionluk.com/site/c/ic-sm-twitter.svg" class="social-media-icon"/>
          </a>
          <a href="https://instagram.com/bionlukcom" target="_blank">
            <img onload="SVGInject(this)"  src="https://gcdn.bionluk.com/site/c/ic-sm-instagram.svg" class="social-media-icon"/>
          </a>
        </div>

        <div style="display: flex; flex-direction: column; margin-top: 35px">
          <a href="https://play.google.com/store/apps/details?id=com.bionluk.androidapp" target="_blank">
            <img src="https://gcdn.bionluk.com/site/c/google-play.svg" class="store-icon"/>
          </a>

          <a href="https://itunes.apple.com/tr/app/bionluk/id1330144140?mt=8" target="_blank">
            <img src="https://gcdn.bionluk.com/site/c/app-store.svg" class="store-icon" style="margin-top: 20px;"/>
          </a>

          <div style="background: #fff; border-radius: 3px; margin-top: 30px;">
            <img style="width: 150px;"   src="https://gcdn.bionluk.com/uploads/message/2aa3c0cd-dbbe-4b8e-b9b8-65ae886d360d.svg"/>

          </div>

        </div>




      </div>
    </div>

    <div style="display: flex; justify-content: space-between; margin-top: 40px;">
      <div style="display: flex;">
        <router-link v-for="(footerLink, index) in footerLinks.section2.list" :to="footerLink.url" v-if="!isFooterLinksEmpty" :key="'section2' + index" class="section-hakkimizda">
          {{footerLink.name}}
        </router-link>
      </div>

      <p class="section-hakkimizda">Bionluk Bilgi Teknolojileri Paz. ve Tic. A.Ş. © 2024</p>
    </div>

  </div>

</template>

<script>
  export default {
    name: "src-pages-components-shared-bottomFooter",
    data() {
      return {
        footerLinks: {}
      };
    },
    computed: {
      isFooterLinksEmpty() {
        return !Object.keys(this.footerLinks).length
      }
    },
    created() {
      this.footerLinks = this.$store.state.footerLinks || {}
    }
  }
</script>

<style scoped lang="scss">

  .footer-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .footer-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  .logo-div {
    display: flex;
    flex-direction: column;
    img {
      margin-bottom: 16px;
      width: 120px;
      height: 29px;
    }
    p {
      font-size: 14px;
      color: #9ca5ae;
      width: 120px;
    }
  }

  .sections-body {
    display: flex;
    flex-direction: column;
  }

  .section-header {
    font-size: 18px;
    font-weight: 600;
    color: #bfc8d2;
    margin-bottom: 29px;
  }

  .sections {
    font-size: 16px;
    text-align: left;
    color: #d2dbe4;
    margin-bottom: 15px;
  }

  .section-hakkimizda {
    font-size: 14px;
    text-align: left;
    color: #8b95a1;
    margin-right: 29px;
  }

  .social-media-icon /deep/ {
    width: 24px;
    height: 24px;
    path {
      fill: #fff;
    }
  }

  .store-icon {
    width: 160px;
    height: 52px;
  }

  .card-icons /deep/ {
    width: 200px;
    path {
      fill: #fff;
    }
  }
</style>
